import React  from "react";

function AudioThings() {
    return (
        <center style={{marginTop:"60px"}}>
            <iframe title="Audio Player" frameborder="0" src="https://itch.io/embed-upload/8618443?color=333333" allowfullscreen="false" width="80%" height="1075">
                <a href="https://thatowlguy.itch.io/silly-intent">Play Silly Intent on itch.io</a>
            </iframe>
        </center>
    );
}

export default AudioThings;