import React  from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navigation from "../Navigation/navigation";
import Projects from "../Projects/projects";
import Contact from "../Contact/contact";
import AudioThings from "../Audio Things/audioThings";

function Main() {
  const homePage = (
    <div>
        <br/>
        <br/>
        <h1>Welcome to the nest.</h1>
        <div>
          <br/>
          <h2> I'm a game designer, software engineer, & armchair <br/>philosopher.</h2>
          <br/>
          <h3>
              Take a look around!
              <br />
              There might be something here for you.
          </h3>
        </div>
    </div>
  );

  return (
    <>
      <Navigation />
        <div className="center-column">
          <BrowserRouter>
            <Routes>
                <Route index element={homePage} />
                <Route path="/projects" element={<Projects />}/>
                <Route path="/audio-things" element={<AudioThings />}/>
                <Route path="/contact" element={<Contact />}/>
            </Routes>
          </BrowserRouter>
        </div>
    </>
  );
}

export default Main;
