import React  from "react";
import { motion } from "framer-motion";
import { turnLinkGreen } from "../motion-states";

function Navigation() {
  return (
    <>
        <img class="that-logo" src="That Owl Logo.png" alt="(OvO)"/>
        <div>
          <span className="navbar">
            <motion.a whileHover={turnLinkGreen} className="navbar-link" href="/">Home</motion.a>
            <motion.a whileHover={turnLinkGreen} className="navbar-link" href="/projects">Projects</motion.a>
            <motion.a whileHover={turnLinkGreen} className="navbar-link" href="/contact">Contact</motion.a>
          </span>
          <image className="social-logo" src="socials/twitter.png"/>
        </div>
        <div className="socialbar">
          <motion.a className="socialbutton" whileHover={turnLinkGreen} href="https://www.tiktok.com/@thatowlguy">tiktok</motion.a>
          <motion.a className="socialbutton" whileHover={turnLinkGreen} href="https://twitter.com/thatowlguy">twitter</motion.a>
          <motion.a className="socialbutton" whileHover={turnLinkGreen} href="https://instagram.com/thatowlguy">instagram</motion.a>
          <motion.a className="socialbutton" whileHover={turnLinkGreen} href="https://www.threads.net/@thatowlguy">threads</motion.a>
          <motion.a className="socialbutton" whileHover={turnLinkGreen} href="https://tumblr.com/thatowlguy">tumblr</motion.a>
        </div>
    </>
  );
}

export default Navigation;
