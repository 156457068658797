import React  from "react";
import { motion } from 'framer-motion';
import { turnLinkGreen } from "../motion-states";

function Contact() {

    const formButton = {
        fontSize:"50px",
        borderStyle:"solid",
        borderRadius: "10px",
        padding: "20px"
    };

    return (
        <div style={{marginTop:"80px"}}>
            <h1>Get in touch!</h1>
            <h3>Fill out this form and I'll get back to you.</h3>
            <br/><br/>
            <motion.a whileHover={turnLinkGreen} style={formButton} className="navbar-link" href="https://forms.gle/wHc1WqXJJNBervjJA">
                Contact Form
            </motion.a>
        </div>
    );
}

export default Contact;