import React  from "react";
import { motion } from 'framer-motion';
import { turnLinkGreen } from "../motion-states";

function Projects() {
    const imageStyle = {width:"75%", margin: "auto"};

    return (
        <div style={{marginTop:"70px"}}>
            <h1>Projects</h1>
            <div style={{width:"50%", margin: "auto"}}>
                <h2>Adventum</h2>
                <p>
                    A Table-Top Role Playing Game that is currently in development. It's rules and site are in a
                    pre-alpha state.The game and its core ruleset will be publicly available to all once the game
                    reaches early access.
                </p>
                <p>
                    Game content can be accessed via an application to the Adventum Waitlist. Playtesting is also
                    underway and selection for voluntary playtesting is also possible by applying for pre-alpha
                    content access.
                </p>
                <h3>
                <motion.a className="nav-link" style={{padding:"15px", fontSize:"35px"}} whileHover={turnLinkGreen}
                href="https://forms.gle/4YqtAZiG6dheF8Ch7" target="_blank">
                    Apply to Waitlist
                </motion.a>
                </h3>
            </div>
            <span className="project-box">
                <div style={{width:"75%", margin: "auto", padding: "auto"}}>
                    <h2>Audio Things</h2>
                    <p>
                        Sometimes I make music and some either fewer times I let you all listen to it. Here's some of that music
                        <motion.a className="nav-link" whileHover={turnLinkGreen} href="/audio-things">
                            HERE.
                        </motion.a>
                    </p>
                </div>
            </span>
            <span className="project-box">
                <div style={{width:"40%"}}>
                    <h2>Mews</h2>
                    <p>
                        A cute and creepy sketching platformer in early development.
                        If you're looking for pitches for a mobile publisher, get in contact with me here
                        <motion.a className="nav-link" whileHover={turnLinkGreen} href="/contact">
                            HERE.
                        </motion.a>
                    </p>
                </div>
                <div style={{width:"55%", height:"auto", margin:"20px"}}>
                    <img src="mews/Sludge.png" style={imageStyle} alt="Sludge the Demon Cat"/>
                </div>
            </span>
            <span className="project-box">
                <div style={{width:"50%", height:"auto"}}>
                    <img src="mhmvr/vrPortal.png" style={imageStyle} alt="A look into the MHMVR virtual space."/>
                </div>
                <div style={{width:"50%"}}>
                    <h2>Missouri History Museum Virtual Reality</h2>
                    <p>
                        A digital initiatives internship collaboration with the Missouri Historical Society.
                        It was an early look at what a full VR exhibit might look like on your smart-phone. 
                    </p>
                    <p>
                        The technology used in this are from the early days of modern VR, but you can still access the prototype on
                        <motion.a className="nav-link" whileHover={turnLinkGreen} href="https://thatowlguy.itch.io/mhmvr" target="_blank">
                            itch.io
                        </motion.a>
                    </p>
                </div>
            </span>
            <span className="project-box">
                <div style={{width:"50%"}}>
                    <h2>OSVR Art Exhibit</h2>
                    <p>
                    A virtual reality art exhibition held in spring of 2016 at 314: Ripon College Project Space in downtown Ripon.
                    </p>
                </div>
                <div style={{width:"50%", height:"auto"}}>
                    <img src="osvr/osvrArtExhibit.jpg" style={imageStyle} alt="An exhibit visitor looking into the virtual gallery."/>
                </div>
            </span>
        </div>
    );
}

export default Projects;